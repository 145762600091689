import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import hoikupedia from '../assets/images/hoikupedia.jpg'
import eceTransfer from '../assets/images/ece-transfer.gif'
const Hoikupedia = props => (
  <Layout>
    <Helmet>
      <title>About</title>
      <meta name="description" content="Project - Hoikupedia" />
    </Helmet>
    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Hoikupedia website</h1>
          </header>
          <p>
            COS Educational Consulting, Inc. is a Vancouver-based study abroad
            agency, providing consultation services for Canadian Immigration.
            They work with our partners in the field of Early Child Care to
            connect our applicants with suitable roles and recruiters.
            <br />
            <br />
            "Hoikupedia" is the website provides information for japanese
            applicants who want to work in the field of Early Child Care.
            <br />
            <br />
            As a front-end developer, I created a custom WordPress theme from
            scratch, using PHP. JQuery and TypeScript were also used to create
            additional features. <br />
            This approach allows the team at COS to update content and add or
            remove pages without the need of editing code.
            <br />
          </p>
          <tbody>
            <tr>
              <th>Type</th>
              <th>Stack</th>
              {/* <th>Code</th> */}
              <th>Live</th>
            </tr>
            <tr>
              <td>Contract</td>
              <td>WordPress</td>
              {/* <td class="ecommerce">
                            <a href="https://github.com/taekimura/ecommerce-website">Repository</a>
                            </td> */}
              <td class="Decore__Bold-sc-1tbn2mo-3 gicxJS">
                <a
                  href="https://hoikupedia.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Site
                </a>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>PHP</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>jQuery</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>TypeScript</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <span className="image main">
            <img src={eceTransfer} alt="" />
          </span>
          <span className="image main">
            <img src={hoikupedia} alt="" />
          </span>
          <ul className="actions2">
            <li>
              <Link to="/#projects">Back</Link>
            </li>
          </ul>
          <br />
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dapibus rutrum facilisis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam tristique libero eu nibh porttitor fermentum. Nullam venenatis erat id vehicula viverra. Nunc ultrices eros ut ultricies condimentum. Mauris risus lacus, blandit sit amet venenatis non, bibendum vitae dolor. Nunc lorem mauris, fringilla in aliquam at, euismod in lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In non lorem sit amet elit placerat maximus. Pellentesque aliquam maximus risus, vel sed vehicula.</p>
                    <p>Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque venenatis dolor imperdiet dolor mattis sagittis. Praesent rutrum sem diam, vitae egestas enim auctor sit amet. Pellentesque leo mauris, consectetur id ipsum sit amet, fersapien risus, commodo eget turpis at, elementum convallis elit. Pellentesque enim turpis, hendrerit tristique lorem ipsum dolor.</p> */}
        </div>
      </section>
    </div>
  </Layout>
)
export default Hoikupedia
